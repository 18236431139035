exports.components = {
  "component---src-components-template-careers-detailed-js": () => import("./../../../src/components/template/careers-detailed.js" /* webpackChunkName: "component---src-components-template-careers-detailed-js" */),
  "component---src-components-template-careers-fresh-graduate-js": () => import("./../../../src/components/template/careers/fresh-graduate.js" /* webpackChunkName: "component---src-components-template-careers-fresh-graduate-js" */),
  "component---src-components-template-careers-professional-js": () => import("./../../../src/components/template/careers/professional.js" /* webpackChunkName: "component---src-components-template-careers-professional-js" */),
  "component---src-components-template-events-js": () => import("./../../../src/components/template/events.js" /* webpackChunkName: "component---src-components-template-events-js" */),
  "component---src-components-template-news-jfe-engineering-malaysia-js": () => import("./../../../src/components/template/news/jfe-engineering-malaysia.js" /* webpackChunkName: "component---src-components-template-news-jfe-engineering-malaysia-js" */),
  "component---src-components-template-news-js": () => import("./../../../src/components/template/news.js" /* webpackChunkName: "component---src-components-template-news-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-global-js": () => import("./../../../src/pages/global.js" /* webpackChunkName: "component---src-pages-global-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-at-jfe-js": () => import("./../../../src/pages/life-at-jfe.js" /* webpackChunkName: "component---src-pages-life-at-jfe-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

